<template>
  <div>
    <topBarY />
    <div class="bg">
      <img src="../../../assets/common/pic/bgss.jpg" alt="">
    </div>
    <div class="switchBar">
      <div class="tab contact">智能无线保管柜</div>
    </div>
    <div class="line"></div>
    <div class="switchContiner">
      <div class="continer contactContiner">
        <div class="txt">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;智能保管柜系统基于模块化设计，运用RFID射频技术、自动控制技术、工业网络技术、互联网技术，
          具有运行稳定、功能丰富，根据需求可灵活组合，智能化功能丰富，
          客户定制便利，便于扩展、单元自持等特点。基于超高频RFID扫描系统，物资识别率达到99.9%，扫描速度1～10S可调。<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;智能保管柜系统管理功能丰富，接口灵活，具有良好的扩展性，
          支持远程Web操作与基于物联网及云数据的管理。全面支持中心主站、云主站的构架，规模可随意配置，
          不受某个系统的物理容量限制。在无人仓建设中可便利的与其它仓储智能化系统构成一个整体。
        </div>
        <div class="pic">
          <img src="../../../assets/product/cabinet.png" alt="">
        </div>
      </div>
    </div>
    <baseBar />
  </div>
</template>
  
<script>
import topBarY from '@/components/topBarY.vue'
import baseBar from '@/components/baseBar.vue'

export default {
  name: 'Robot',
  components: {
    topBarY,
    baseBar
  },
  created() {
    this.$nextTick(() => {
      // 1.禁用右键菜单
      document.oncontextmenu = new Function("event.returnValue=false");
      // 2.禁用鼠标选中
      document.onselectstart = new Function("event.returnValue=false");
      // 3.禁止键盘F12键
      document.addEventListener("keydown", function (e) {
        if (e.key == "F12") {
          e.preventDefault(); // 如果按下键F12,阻止事件
        }
      });
    });
  }
}
</script>
  
<style scoped>
.bg {
  position: relative;
  width: 1920px;
  height: 480px;
  overflow: hidden;
}

.bg img {
  position: absolute;
  top: -100px;
  width: 1920px;
}

.switchBar {
  width: 1920px;
  height: 70px;
  background-color: #fff;
  text-align: center;
}

.switchBar .tab {
  display: inline-block;
  margin-right: 30px;
  margin-top: 15px;
  border-radius: 5px;
  width: 150px;
  height: 40px;
  background-color: #c3a35d;
  color: #f5f5f5;
  font-family: 'Microsoft Yahei';
  font-size: 16px;
  line-height: 40px;
}

.line {
  margin: 0 auto;
  width: 1400px;
  height: 1px;
  background-color: #c3a35d;
}

.switchContiner {
  width: 1920px;
  padding: 20px;
  background-color: #f7f7f7;
}

/* 关于宁讯 */
.switchContiner .contactContiner {
  margin: 0 auto;
  padding: 40px;
  padding-left: 300px;
  padding-right: 300px;
  width: 1400px;
  height: 700px;
  border-radius: 5px;
  background-color: #fff;
}

.switchContiner .contactContiner .txt {
  font-size: 18px;
  line-height: 34px;
}

.switchContiner .contactContiner .pic {
  margin-top: 40px;
  text-align: center;
}

.switchContiner .contactContiner .pic img {
  width: 500px;
}
</style>
